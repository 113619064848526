export const environmentData = {
  run: "production",
  development: {
    clientId: "c1842ba4-5674-4d50-8eb1-0740d17a8227",
    authority: "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope": "openid c1842ba4-5674-4d50-8eb1-0740d17a8227/user_impersonation",
    apiBaseURL: "https://hm-oa-insights-api-dev.azurewebsites.net/api",
    heatMapUrl:
      "https://web-oa-iv-insights-dev-gfc3guabcyefc3aq.swedencentral-01.azurewebsites.net/get-data",
    openProblemURL:
      "https://app-openmajorproblem-insights-prod-c7fdbbhbb6f8f9dh.swedencentral-01.azurewebsites.net/OpenMajorProblemInsights",
    businessStructureInsights:
      "https://businessstructure-insights-dev-djc4edc4czc3fmc0.swedencentral-01.azurewebsites.net",
  },
  production: {
    clientId: "369a0357-3b42-434c-8941-862aecc6f59f",
    authority: "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope": "openid 369a0357-3b42-434c-8941-862aecc6f59f/user_impersonation",
    apiBaseURL:
      "https://hm-oa-insights-api-prod-gudzcdghf2g3drbk.swedencentral-01.azurewebsites.net/api",
    heatMapUrl:
      "https://web-oa-iv-insights-dev-gfc3guabcyefc3aq.swedencentral-01.azurewebsites.net/get-data",
    openProblemURL:
      "https://app-openmajorproblem-insights-prod-c7fdbbhbb6f8f9dh.swedencentral-01.azurewebsites.net/OpenMajorProblemInsights",
    businessStructureInsights:
      "https://businessstructure-insights-prod-hucjdcasa3a5bha6.swedencentral-01.azurewebsites.net",
  },
}
